<template>
  <v-container text-xs-center fluid>
    <v-card elevation="0">
      <v-layout>
        <v-flex xs12 sm12 md12 style="padding: 1%">
          <v-card>
            <v-card-title>
              <v-icon large>mdi-file</v-icon>

              <v-row justify="end">
                <v-col style="padding-top: 40px;"> Files</v-col>
                <v-col>
                  Total Usage
                  <h3 v-if="$store.getters.companyDetails.usage < 1024">
                    {{
                    $store.getters.companyDetails.usage == "null" ||
                      $store.getters.companyDetails.usage == 0
                      ? mathfloorCustom(0)
                      : mathfloorCustom($store.getters.companyDetails.usage)
                  }}
                    /
                    {{
                      mathfloorCustom(
                        $store.getters.companyDetails.plan.storageLimit
                      )
                    }}
                  </h3>
                  <h3 v-else>
                    {{ $store.getters.companyDetails.usage / 1024 }}GB /
                    {{
                    Math.round(
                      $store.getters.companyDetails.plan.storageLimit / 1024
                    )
                  }}GB
                  </h3>
                  <v-progress-linear color="light-green " height="20" striped :value="($store.getters.companyDetails.usage /
                      $store.getters.companyDetails.plan.storageLimit) *
                    100
                    "></v-progress-linear>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-data-table :headers="fileTableHeaders" :items="files" :loading="loading" :items-per-page="records"
                item-key="id" hide-default-footer></v-data-table>
              <v-divider style="margin-bottom: 10px"></v-divider>
            </v-card-text>
            <v-card-text>
              <div class="v-data-footer">
                <div class="v-data-footer__select">
                  Rows per page:
                  <div
                    class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select">
                    <v-select v-model="records" :items="recordsPerPage" style="margin: 5px"
                      @change="pagination"></v-select>
                  </div>
                </div>
                <div class="v-data-footer__select">
                  <div class="v-data-footer__icons-before">
                    <v-btn :disabled="page == 1 ? true : false" color="primary" fab small text @click="prevSet">
                      <v-icon :size="24">arrow_left</v-icon>
                    </v-btn>
                  </div>
                  <div class="v-data-footer__pagination">
                    {{ `${page}/${pageCount} of ${totalRecords}` }}
                  </div>
                  <div class="v-data-footer__icons-after">
                    <v-btn :disabled="page >= pageCount ? true : false" color="primary" fab small text @click="nextSet">
                      <v-icon :size="24">arrow_right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs12 sm12 md12 style="padding: 1%">
          <v-card>
            <v-card-title>
              <v-icon large>mdi-database-plus</v-icon>
              <span class="title" style="padding-left: 5px">Upgrade Storage</span>
            </v-card-title>
            <v-card-text>
              <div>
                <v-layout row wrap style="margin: 0px">
                  <v-flex xs12 sm12 md12 style="padding: 0%">
                    <v-container justify="center">
                      <h6 class="title font-weight-light" style="margin-top: 5px; text-align: center">
                        <!-- <h5 class="title font-weight-light">
                          {{ Math.round(addStorage / 10) }}GB
                        </h5> -->
                        Please contact us at support@godial.cc
                      </h6>
                      <v-layout row wrap style="padding-bottom: 4%;">
                        <v-flex xs12 sm12 m12> </v-flex>
                      </v-layout>

                      <v-layout style="justify-content: center">
                        <v-btn color="green" style="color: white"><a href="mailto:support@godial.cc"
                            style="color: white;text-decoration:none;">Contact Us</a></v-btn>
                      </v-layout>
                    </v-container>
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
export default {
  data() {
    return {
      recordCallsWithTrueCaller: false,
      enableRecording: false,
      addStorage: 0,
      loading: false,
      recordsPerPage: [1, 5, 15, 30, 60],

      page: 1,
      pageCount: 0,
      totalRecords: 0,
      records: 5,

      files: [],
      fileTableHeaders: [
        {
          text: "File Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Size", value: "size", sortable: false },
        { text: "Time", value: "createdOn", sortable: false },
        { text: "Category", value: "typeOfFile", sortable: true },
      ],
    };
  },
  async created() {
    await this.fetchFiles();
    this.recordCallsWithTrueCaller = this.$store.state.companyDetails.recordCallsWithTrueCaller;
    await this.pagination();

    console.log(
      "$store.getters.companyDetails.usage",
      this.$store.getters.companyDetails.usage
    );
  },

  // async mounted() {
  //   this.pagination();
  // },
  methods: {
    mathfloorCustom(value) {
      // console.log("value", value);

      if (value == 0) {
        return "0MB";
      }

      var filterValueInBytes = value * 1024 * 1024;

      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      var i = parseInt(
        Math.floor(Math.log(filterValueInBytes) / Math.log(1024))
      );

      var finalValueData =
        Math.round(filterValueInBytes / Math.pow(1024, i), 2) + "" + sizes[i];

      return finalValueData;
    },
    updateRecordCalls() {
      var self = this;

      self.$http
        .patch(
          `${this.$store.getters.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
          { recordCallsWithTrueCaller: self.recordCallsWithTrueCaller }
        )
        .then((response) => {
          self.$store.dispatch("getCompanyDetails", {
            companyId: self.$store.getters.user.companyId,
          });
          console.log("response", response.body);
        })
        .catch((err) => {
          self.$swal({
            type: "error",
            text: "Something went wrong. Couldn't Update",
          });
        });
    },
    nextSet() {
      this.page++;
      this.pagination();
    },
    prevSet() {
      this.page--;
      this.pagination();
    },
    async pagination() {
      // console.log("Pagination startedddd/................")
      try {

        // console.log("total records: ", this.totalRecords, this.records);
        this.pageCount = Math.ceil(this.totalRecords / this.records);

        // console.log("Page count started: ", this.pageCount);
        if (this.page > this.pageCount) {
          this.page = 1; //set default
        }
        this.files = await this.fetchFiles(
          this.records,
          this.records * this.page - this.records
        );

        // Save default pagination of seperate pages
        this.paginate[
          this.$router.currentRoute.name
            .toString()
            .trim()
            .replace(/ /g, "")
            .toLowerCase()
        ] = {
          page: this.page,
          pageCount: this.pageCount,
          records: this.records,
          totalRecords: this.totalRecords,
        };
        this.$store.commit("setPagination", this.paginate);

        return;
      } catch (e) {
        this.loader = false;
      }
    },

    async fetchFiles(limit, skip) {
      if (typeof limit == "undefined" && typeof skip == "undefined") {
        try {
          var where = {
            companyId: this.$store.getters.user.companyId,
          };
          var response = await this.$http.get(
            this.$store.getters.ENDPOINT +
            "/attachments/count?where=" +
            encodeURIComponent(JSON.stringify(where))
          );

          // console.log("attchment response: ", response.body);

          this.totalRecords = response.body.count;
        } catch (e) {
          console.log(e);
        }
      }
      this.loading = true;

      this.files = [];
      if (limit == undefined || limit == undefined) {
        limit = 5;
        skip = 0;
      }
      var filter = {
        skip: skip,
        limit: limit,
        order: "createdOn DESC",
        where: {
          companyId: this.$store.getters.user.companyId,
          // typeOfFile: "recording",
        },
      };

      var res = await this.$http.get(
        this.$store.getters.ENDPOINT +
        "/attachments?filter=" +
        encodeURIComponent(JSON.stringify(filter))
      );

      // console.log("attchment", res.body);

      this.files = _.map(res.body, (c) => {
        c.size = this.mathfloorCustom(c.size);
        c.createdOn = moment(c.createdOn).format("lll");
        return c;
      });

      console.log(" this.files", this.files);

      this.loading = false;
      return this.files;
    },

    upgradeStorage() { },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .recordPadding {
    padding-left: 30%;
    padding-right: 30%;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .recordPadding {
    padding-left: 30%;
    padding-right: 30%;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .recordPadding {
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .recordPadding {
    padding-left: 1%;
    padding-right: 1%;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .recordPadding {
    padding-left: 1%;
    padding-right: 1%;
  }
}
</style>
